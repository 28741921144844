.text-editor {
    width: 100%;
    margin: 0 auto;
}

.ql-editor {
    min-height: 100px;
}

#toolbar {
    background: #fff;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
}

.list {
    display: flex;
    background-color: #2c254a;
    padding: 15px;
    height: 60px;
    border-radius: 5px;
    align-content: center;
    margin-top: 20px;
    justify-content: space-between;
}

.trash-icons {
    cursor: pointer;
}

.rc-time-picker-panel-select ul li {
    color: #000;
}